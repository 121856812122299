.row-header {
  order: 1;
}

.border-bottom-dashed{
    border-bottom: 1px dashed #dee2e6!important;
}

.border-bottom{
    border-bottom: 1px solid #d3d6da!important;
}