.box{
    width:10rem;
    height: 10rem;

    .item{
        width: 5rem;
        height: 5rem;
        background-color: yellow;
    }
}

.hide {
    display: none;
}

.drag-over {
    border: dashed 3px red;
}