.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fulldemention {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.nav-section {
  width: 100%;
  min-height: 5rem;
  background-color: #f7f7ee;
}

.Banner {
  width: 100%;
  min-height: 20rem;
  background-color: #b6eb7a;
  display: flex;
  align-items: center;
  justify-content: center;
  .banner-title {
    color: white;
  }
}

.main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  .appointment-container{
    padding: 20px 0px 20px 0px;
  }
}
.full-height{
  height: 100%;
}
.App-header {
  //background-color: #282c34;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
