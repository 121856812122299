.canvas{
    width: 30rem;
    height: 30rem;
    background-color: yellow;
}

.test{
    position: absolute;
    width: 3rem;
    height: 3rem;
    background-color: black;
    left: 300px;
    top: 300px;
}

.test1{
    position:absolute;
    width: 3rem;
    height: 3rem;
    background-color: black;
    left: 500px;
    top: 500px;
}