@charset "utf-8";
@import "./node_modules/bulma/bulma.sass";

.timeGrid {
  tr {
    td {
      padding: 0px;
      height: 2rem;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.taken {
  background-color: paleturquoise;
}
