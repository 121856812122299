.img-fluid {
    width: auto;
    max-width: 100%;
    height: 250px;
    margin: 0.3rem 0rem 0rem 0.3rem;
}

.image-wrapper{
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}
