.vertical_grid{
    height: 200vh;
    width: calc(100% - 40px);
    order:3;
    // background-color: floralwhite;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.horizontal_grid{
    display: flex;
    flex-direction: column;
    position: absolute;
}

.row-align{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.column-align{
    width: 100%;
    order:2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}