@charset "utf-8";
@import "./node_modules/bulma/bulma.sass";
a.navbar-item {
  &:focus {
    color: #4a4a4a !important;
  }
  &:focus-within {
    color: #4a4a4a !important;
  }
  &:hover {
    color: #4a4a4a !important;
    text-decoration: none;
  }
}
