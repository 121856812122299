td{
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .cell{
        
        width: 100%;
        height: 100%;
        &:hover{
            border: 2px solid paleturquoise;
        }
    }
    
    .taken{
        background-color: paleturquoise;
    }
}

// .noselect {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }